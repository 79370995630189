export const FedEx = () => (
  <svg
    width="58"
    height="17"
    viewBox="0 0 58 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2597_64184)">
      <path
        d="M54.1016 14.1159C54.1016 13.0227 54.9718 12.2773 55.9663 12.2773C56.9608 12.2773 57.831 13.0227 57.831 14.1159C57.831 15.209 56.9608 15.9544 55.9663 15.9544C54.9966 15.9544 54.1016 15.209 54.1016 14.1159ZM55.9911 13.9171C56.3144 13.9171 56.389 13.7432 56.389 13.5941C56.389 13.4202 56.2895 13.2711 55.9911 13.2711H55.5933V13.9171H55.9911ZM55.5685 14.1904V15.209H55.2453V12.9978H56.0657C56.5381 12.9978 56.7619 13.1966 56.7619 13.619C56.7619 13.8674 56.5879 14.0662 56.3641 14.091C56.563 14.1159 56.6127 14.2898 56.6624 14.5879C56.6873 14.7867 56.737 15.1345 56.8116 15.209H56.4138C56.3144 15.0103 56.3392 14.7121 56.2646 14.4637C56.2149 14.2649 56.1155 14.2152 55.8917 14.2152L55.5685 14.1904ZM57.4829 14.1159C57.4829 13.2214 56.8614 12.5755 55.9911 12.5755C55.1209 12.5755 54.4994 13.2214 54.4994 14.1159C54.4994 15.0103 55.1209 15.6562 55.9911 15.6562C56.8365 15.6562 57.4829 15.0103 57.4829 14.1159Z"
        fill="#FF6200"
      />
      <path
        d="M47.6625 15.9543L45.5242 13.5444L43.4109 15.9543H38.9355L43.3114 11.035L38.9355 6.14062H43.5601L45.7231 8.52572L47.7868 6.14062H52.2372L47.8862 11.035L52.3118 15.9543H47.6625Z"
        fill="#FF6200"
      />
      <path
        d="M30.3574 15.9534V0.5H38.9352V3.95342H33.9874V6.13975H38.9352V9.4441H33.9874V12.5H38.9352V15.9534H30.3574Z"
        fill="#FF6200"
      />
      <path
        d="M24.5398 13.5932C23.1972 13.5932 22.3767 12.3509 22.3767 11.059C22.3767 9.6677 23.0977 8.35093 24.5398 8.35093C26.0316 8.35093 26.6283 9.69255 26.6283 11.059C26.6283 12.3758 26.0067 13.5932 24.5398 13.5932ZM11.6856 9.49379C11.9094 8.52484 12.6801 7.87888 13.6249 7.87888C14.6692 7.87888 15.3902 8.5 15.5891 9.49379H11.6856ZM26.7277 0.5V6.81056H26.678C25.8824 5.8913 24.863 5.56832 23.7193 5.56832C21.3324 5.56832 19.5423 7.18323 18.9207 9.31987C18.1997 6.95963 16.3598 5.51863 13.6001 5.51863C11.3624 5.51863 9.62198 6.51242 8.70205 8.15217V6.13975H4.10239V3.95342H9.12472V0.5H0V15.9534H4.10239V9.4441H8.20479C8.08047 9.91615 8.00588 10.4379 8.00588 10.9596C8.00588 14.1894 10.4673 16.4503 13.6249 16.4503C16.2604 16.4503 18.0008 15.2081 18.9456 12.9472H15.4151C14.9427 13.618 14.5697 13.8168 13.6249 13.8168C12.5309 13.8168 11.5613 12.8478 11.5613 11.705H18.7218C19.0202 14.264 21.0341 16.4752 23.769 16.4752C24.9376 16.4752 26.0316 15.9037 26.7029 14.9099H26.7526V15.9037H30.3826V0.5H26.7277Z"
        fill="#4D148C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2597_64184">
        <rect
          width="57.8313"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
