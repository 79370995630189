import { Checkmark } from '@c/icons/checkmark';

const ItemAddedToast = () => {
  return (
    <div className="flex w-full select-none items-center justify-evenly gap-[1.2rem]">
      <div className="flex items-center rounded-full bg-brand-primary p-[0.5rem]">
        <Checkmark variant="white" height={18} width={18} />
      </div>
      <p className="whitespace-nowrap font-semibold text-brand-black">
        Items successfully added to your cart.
      </p>
    </div>
  );
};

export default ItemAddedToast;
