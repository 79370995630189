export const USPS = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeMiterlimit="1.414"
    width="40"
    height="40"
    viewBox="0 0 50 50"
    clipRule="evenodd"
    strokeLinejoin="round"
  >
    <path fill="#004A87" d="M0 0h50v50H0z" />
    <path
      fill="#fff"
      d="M41.095 41.48 47.86 8.065 0 7.38v34.432l39.707.066 1.388-.398z"
    />
    <path
      fill="#004A87"
      d="M42.46 42.722H0v-1.405c10.56-4.085 40.53-15.69 41.007-16.047 0 0 4.078-6.167 1.57-8.267-.836-.693-1.423-.888-6.187-.86 0 0 .21-1.314-6.595-2.516C29.248 13.534 3.572 8.26 0 7.525V7.28l50-.006-7.54 35.448zM0 15.466h21.428c.434.028 14.19-.352 13.226 2.37H13.037l3.384 10.486c2.998-1.583 13.41-5.726 18.847-5.984 1.783-.083 2.83-.05 2.87.51.012.194-.427.368-1.477.558-2.695.48-7.333 1.906-8.415 2.275-5.675 1.94-11.814 4.96-16.258 7.24-1.18.603-6.957 3.45-11.985 5.923V15.468zm38.288 2.528c1.897 0 3.265.24 3.08 1.84-.132 1.13-1.34 3.342-1.61 3.633-.267.295-.728.34-.493-.33.602-1.733 1.2-3.058.46-3.292-1.403-.448-6.064.53-8.652.53-1.126 0-1.254-.157-1.244-.425.01-.302.22-.38 1.01-.446.812-.073 2.56-.25 3.23-.583v.008c.43-.218.562-.358.73-.575.184-.247.316-.354.47-.354h3.013z"
    />
  </svg>
);

export const UPS = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="58"
    viewBox="0 0 102 115"
  >
    <path
      stroke="none"
      fillRule="nonzero"
      fill="rgb(14.447021%,5.064392%,1.89209%)"
      fillOpacity={1}
      d="M25.777 85.383c-6.07-4.977-9.277-12.125-9.277-20.668V24.098c9.586-5.078 21.191-7.653 34.5-7.653 13.309 0 24.914 2.575 34.5 7.653v40.617c0 8.543-3.207 15.691-9.277 20.668C70.672 89.933 53.027 97.676 51 98.555c-2.027-.88-19.672-8.621-25.223-13.172"
    />
    <path
      stroke="none"
      fillRule="nonzero"
      fill="rgb(97.880554%,69.718933%,4.23584%)"
      fillOpacity={1}
      d="M75.047 94.297a3.03 3.03 0 0 0-3.02 3.027 3.024 3.024 0 0 0 6.047 0 3.03 3.03 0 0 0-3.027-3.027m2.496 3.027a2.487 2.487 0 0 1-2.492 2.485 2.489 2.489 0 0 1-2.489-2.485 2.499 2.499 0 0 1 2.489-2.496 2.497 2.497 0 0 1 2.492 2.496m-2.598-1.312c.567 0 .64.277.64.52 0 .343-.147.573-.858.573h-.082v-1.093Zm-.965-.535v3.675h.665v-1.535h.093c.051.074 1 1.535 1 1.535h.801s-1.008-1.472-1.105-1.617c.484-.117.812-.469.812-.98 0-.52-.32-1.078-1.223-1.078Zm-49.3-8.883C18.348 81.402 15 73.94 15 65.027V22.648c10.004-5.304 22.113-7.992 36-7.992s25.996 2.688 36 7.992v42.38c0 8.913-3.348 16.374-9.68 21.566-5.793 4.75-24.207 12.828-26.32 13.746-2.113-.918-20.527-8.996-26.32-13.746m40.26-36.246c-.027-5.063 4.282-8.043 8.403-8.164 3.441-.106 5.773 1.187 6.875 2.035v5.445c-1.305-1.34-3.422-2.555-5.61-2.574-1.773-.016-3.625.765-3.687 2.992-.059 2.281 1.785 3.18 4.121 4.55 5.234 3.075 6.242 5.84 6.125 9.493-.121 4.004-2.883 8.379-9.07 8.379-2.477 0-4.922-.774-6.883-1.945v-5.766c1.597 1.426 3.988 2.535 5.984 2.547 2.297.012 3.813-1.305 3.758-3.488-.047-1.965-1.16-3.032-3.914-4.653-5.164-3.027-6.094-5.523-6.102-8.851M49.76 66.922c.45.168 1.184.379 2.032.379 3.605 0 5.328-3.309 5.328-10.156 0-7.016-1.508-9.918-5.14-9.918-.848 0-1.711.199-2.22.453Zm0 18.308h-6.265V44.41c2.27-1.465 5.027-2.226 8.441-2.226 7.477 0 11.586 5.671 11.586 14.8 0 9.153-3.992 15.414-10.945 15.414-1.285 0-2.191-.171-2.816-.355ZM21.505 62.504V42.816h6.262v19.965c0 1.778.43 4.41 3.3 4.41 1.274 0 2.282-.296 2.961-.765v-23.61h6.254v27.172c-2.39 1.625-5.605 2.516-9.437 2.516-6.196 0-9.34-3.36-9.34-10M83.69 23.539c-4.445-.414-9.062-.687-13.77-.687-17.444 0-36.151 3.75-51.612 17.933v24.242c0 7.899 2.918 14.493 8.468 19.012C31.672 88.031 46.75 94.852 51 96.727c4.195-1.848 19.234-8.582 24.219-12.688C80.8 79.441 83.69 73 83.69 65.027V23.54"
    />
  </svg>
);
