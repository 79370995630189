// Import necessary modules and components
import RootLayout from '@c/layouts/RootLayout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { pageView, FB_PIXEL_ID, GA_TRACKING_ID } from '@util/analytics/analytics.service';
import { getHostUrl } from '@util/index';
import { generateRandomId, logError } from '@util/logError';
import AuthProvider from 'context/AuthContext';
import { ChatProvider } from 'context/ChatContext';
import { ShoppingCartProvider } from 'context/ShoppingCartContext';
import { StripeProvider } from 'context/StripeContext';
import { ToastProvider } from 'context/ToastContext';
import { NextPage } from 'next';
import type { AppProps, AppType } from 'next/app';
import Head from 'next/head';
import { useRouter as useNavigation } from 'next/navigation';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/globals.css';
import AppErrorBoundary from './AppErrorBoundary';
import { HeadMetaTag } from './blog/[...slug]';


// Define custom types
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Create a QueryClient instance with default options
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

// A component to provide context providers and error handling
function ProviderProvider({ children }: { children: ReactNode }) {
  const router = useNavigation();
  const id = useMemo(() => generateRandomId(), []);
  
  return (
    <ErrorBoundary
      onError={(error, { componentStack }) => {
        logError(error, id, componentStack);
      }}
      fallbackRender={({ error }: { error: Error }) => {
        const msg = error instanceof Error ? error.message : error;
        return <AppErrorBoundary message={msg} id={id} />;
      }}
      onReset={() => router.refresh()}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <StripeProvider>
            <ToastProvider>
              <ShoppingCartProvider>
                <ChatProvider>{children}</ChatProvider>
              </ShoppingCartProvider>
            </ToastProvider>
          </StripeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
// Define the main App component
const App: AppType = ({ Component, pageProps }: AppPropsWithLayout) => { 
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const layout = getLayout(<Component {...pageProps} />);
  const showDots = pageProps.title && pageProps.title.length > 87;

  const defaultTitle = router.asPath.includes('/dashboard')
    ? 'Dashboard | Gear Focus'
    : 'Gear Focus - Buy and Sell Camera Gear';

  useEffect(() => {
    // Track page view using Google Analytics
    pageView(window.location.href);
    const handleRouteChange = (url: string) => {
      pageView(url);
    };

    // Add event listener to track route changes
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // Set canonical URL for SEO
  const canonicalUrl = router.query.redirect
    ? `https://www.gearfocus.com${router.asPath.split('?')[0]}`
    : `https://www.gearfocus.com${router.asPath}`;

  return (
    <RootLayout>
      <Head>
        <title>
          {pageProps.title
            ? `${pageProps.title?.slice(0, 90)}${showDots ? '...' : ''}`
            : defaultTitle}
        </title>

        {pageProps.metaTags?.map((tag: HeadMetaTag, i: number) => (
          <meta
            key={tag.key || i}
            property={tag.property}
            content={tag.content}
            {...tag.attributes}
          />
        ))}

        <meta 
          //http-equiv
          httpEquiv="Content-Security-Policy" 
          content="default-src *; 
            style-src 'self' 'unsafe-inline'; 
            script-src 'self' 'unsafe-inline' 'unsafe-eval' 'unsafe-hashes'
              http://www.google.com
              https://js.stripe.com
              https://r.stripe.com
              https://www.googletagmanager.com
              https://cdnjs.cloudflare.com
              https://connect.facebook.net
              https://maps.googleapis.com
              https://apis.google.com
              https://pagead2.googlesyndication.com
              https://tpc.googlesyndication.com
              https://googleads.g.doubleclick.net
              https://vercel.live
              https://www.gstatic.com
              https://us-central1-gear-focus-staging.cloudfunctions.net
              https://us-central1-gear-focus.cloudfunctions.net
              https://firestore.googleapis.com
              https://gear-focus-default-rtdb.firebaseio.com
              https://s-usc1a-nss-2032.firebaseio.com
              https://www.clarity.ms;
            img-src * blob:;" 
        />

        <meta name="google-adsense-account" content="ca-pub-5059820352612148" />
        
        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          key="og:site_name"
          property="og:site_name"
          content="Gear Focus, LLC"
        />
        <meta
          key="og:url"
          property="og:url"
          content={getHostUrl() + router.asPath}
        />
        <meta name="author" content="Gear Focus" />
        <meta charSet="UTF-8" />

        <link rel="canonical" href={canonicalUrl} />
        <link rel="dns-prefetch" href="https://google.com" />
        <link rel="dns-prefetch" href="https://gearfocus.b-cdn.net" />
      </Head>
      
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){
            w[l] = w[l] || [];
            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://ss.gearfocus.com/ckxneunp.js?st=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'TTZRC24');
        `,
        }}
      />

      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
        }}
      />

      <Script
        id="adsense-id"
        async
        crossOrigin="anonymous"
        strategy="lazyOnload"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />
      
      <Script
        id="microsoft-clarity"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "lbgffewkui");
          `,
        }}
      />

      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js"
        strategy="lazyOnload"
        async
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="googletagmanager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            // Google Shopping Purchase Tracking Code
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://www.googleadservices.com/pagead/conversion/${GA_TRACKING_ID}/?value=1.0&currency_code=USD&label=ULkqCPnvpuMYEMvduN4C');
          `,
        }}
      />
      
      <ProviderProvider>{layout}</ProviderProvider>
    </RootLayout>
  );
};

export default App;
