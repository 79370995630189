const ErrorToast = ({ message }: { message?: string }) => {
  return (
    <div className="flex w-full select-none items-center">
      <p className="grow whitespace-nowrap text-center font-semibold text-brand-white">
        {message ?? 'Items successfully added to your cart.'}
      </p>
    </div>
  );
};

export default ErrorToast;
